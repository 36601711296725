import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserStore } from './user.store';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(protected router: Router, protected userStore: UserStore, protected toastController: ToastController) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modified = req;
    if (!req.headers.has('Skip-Auth')) {
      const reference = this.userStore.user
        ? this.userStore.user.account.reference
        : null;
      modified = req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Account-Reference': reference,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: this.userStore.user.token,
        },
      });
      return next.handle(modified).pipe(
        catchError((error: any) => {
          if (error.status === 401 && !error.url.includes('staff/login')) {
            this.userStore.logout();
            this.router.navigate(['auth/login']);
            return EMPTY;
          }

          if (error.status === 403 && !error.url.includes('staff/login')) {
            const message = error.error?.message ?? 'Insufficient Permissions';
            this.router.navigate(['auth/login']);
            this.showErrorModal(message);
            return EMPTY;
          }

          return throwError(() => error);
        })
      );
    } else {
      const headers = req.headers.delete('Skip-Auth');
      modified = req.clone({ headers });
      return next.handle(modified);
    }
  }

  async showErrorModal(message: string) {
    const toast = await this.toastController.create({
      message: `${message}. Cannot view this page.`,
      duration: 3000,
      position: 'top',
    });

    toast.present();
  }
}
